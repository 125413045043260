import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "gatsby-image"
import SEO from "../components/seo"

const ContactsPage = ({ data }) => (
  <Layout>
    <SEO title="Contacts" />
    <Image fluid={data.illustration.childImageSharp.fluid} />
    <div>
      <p>You can find us on <a href="//www.facebook.com/pages/Feueryetal/230995923772976">Facebook</a>.</p>
      <p>Please like us (if you do!) and spread the word among your friends – the more popular we are the further we will delve to gather more stories and background about Th’eia!</p>
      <p>You can also contact us directly via <a href="mailto:Feuery.et.al@gmail.com">email</a></p>.
    </div>
  </Layout>
)

export default ContactsPage

export const query = graphql`
  query {
    illustration: file(relativePath: { eq: "page_contacts.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`